<template>
    <div class="row settings-page">
        <div class="col-md-5">
            <b-table
                    responsive
                    hover
                    :items="usersAll"
                    :fields="usersAllFields"
                    @row-clicked="onUsersRowClick"
                    :tbody-tr-class="rowClass"
            >
                <template v-slot:cell(username)="data">
                    {{ data.item.firstName }} {{ data.item.lastName }}
                </template>
                <template v-slot:cell(role)="data">
                    {{showRole(data.value)}}
                </template>
                <template v-slot:cell(actions)="data">
                    <button
                            class="btn-edit"
                            @click="onUserEdit(data.item)"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Edit User Details', placement:'leftbottom'}"
                    ></button>
                    <button
                            class="btn-trush"
                            @click="onClickRemoveUser(data.item)"
                            v-b-tooltip.hover="{customClass: 'ps-tooltip', html: true, title:'Delete User', placement:'leftbottom'}"
                    ></button>
                </template>
            </b-table>
        </div>

        <div class="col-md-7">
            <!-- Users Detail Tab View -->
            <div class="detail-section">
                <div class="section-header">Detail</div>

                <div class="detail-body row">
                    <div v-if="selectedUser.userPhoto" class="col-md-4 user-avatar-section">
                        <div class="user-photo">
                            <img :src="selectedUser.userPhoto" alt="User Image"/>
                        </div>

                        <button class="btn btn-danger" @click="onClickDeletePhoto()">
                            Delete Photo
                        </button>

                    </div>
                    <div v-show="!selectedUser.userPhoto" class="col-md-4 user-avatar-section">
                        <vue-dropzone
                                v-on:vdropzone-file-added="addImgUpload"
                                v-on:vdropzone-complete="completeImgUpload"
                                v-on:vdropzone-sending="sendImgUpload"
                                v-on:vdropzone-success="successImgUploadSelectedUser"
                                ref="imgUpDropUsers"
                                id="imgUpDropUsers"
                                class="dropify-wrapper dropzoneContainer"
                                :options="selectedUserAvatarDropdownOptions"
                                :useCustomSlot="true">
                            <div class="dropify-message" style="transform: translateY(0%);">
                                <i class="bx bx-cloud-upload"></i>
                                <p class="upload-title">Upload Photo</p>
                            </div>
                        </vue-dropzone>
                    </div>

                    <div class="col-md-8 user-detail-section">
                        <div class="form-group row">
                            <h3 class="user-name">{{ selectedUser.firstName }}
                                {{ selectedUser.lastName }}</h3>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label" for="account-status">Account Status</label>
                            <div class="col-lg-9">
                                <div v-if="!isUsersEdit || Number(selectedUser.id) === Number(currentUser.id)"
                                     class="d-flex">
                                    <label v-if="selectedUser.isActive"
                                           class="col-form-label form-text">
                                        <div class="active-icon"></div>
                                        <span class="active-user-text">Active User</span>
                                    </label>
                                    <label v-else class="col-form-label form-text">
                                        <div class="active-icon inactive"></div>
                                        <span class="active-user-text">Not Active User</span>
                                    </label>
                                </div>
                                <div v-else class="d-flex pt-0-25">
                                    <toggle-button v-model="selectedUser.isActive"
                                                   color="#5E79FF"
                                                   :sync="true"
                                                   :width="40"
                                                   :labels="false"/>
                                    <span class="form-text text-active d-inline-block col-form-label">
                                        <template v-if="selectedUser.isActive">Active</template>
                                        <template v-else>Not Active</template>
                                    </span>

                                </div>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="username" class="col-lg-3 col-form-label">Username</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        class="col-form-label form-text"
                                >{{ selectedUser.username }}</label>
                                <input
                                        v-else
                                        type="text"
                                        class="form-control"
                                        v-model="selectedUser.username"
                                        id="username"
                                />
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="first_name" class="col-lg-3 col-form-label">First Name</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        class="col-form-label form-text"
                                >{{ selectedUser.firstName }}</label>
                                <input
                                        v-else
                                        type="text"
                                        class="form-control"
                                        v-model="selectedUser.firstName"
                                        id="first_name"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="last_name" class="col-lg-3 col-form-label">Last Name</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        class="col-form-label form-text"
                                >{{ selectedUser.lastName }}</label>
                                <input
                                        v-else
                                        type="text"
                                        class="form-control"
                                        v-model="selectedUser.lastName"
                                        id="last_name"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label">Email Address</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        class="col-form-label form-text"
                                >{{ selectedUser.email }}</label>

                                <email
                                    v-else
                                    :email="selectedUser.email"
                                    @onChange="(value) => selectedUser.email = value"
                                    @checked="(value) => isCheckedSelectedUserEmail = value"
                                ></email>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="location" class="col-lg-3 col-form-label">Business Location</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        for="location"
                                        class="col-form-label form-text">
                                    <template v-if="selectedUser.address">
                                        {{ getLocation(selectedUser.address).name }}
                                    </template>
                                </label>

                                <multiselect
                                        v-else
                                        ref="repairer-user-address-options"
                                        v-model="selectedUser.address"
                                        :options="locations"
                                        :showLabels="false"
                                        :label="'name'"
                                        :option-height="29"
                                        :max-height="203"
                                        :close-on-select="true"
                                        placeholder="Business Location"
                                />
                                <!--                                <b-form-select-->
                                <!--                                    v-else-->
                                <!--                                    :options="locations"-->
                                <!--                                    v-model="selectedUser.address"-->
                                <!--                                    text-field="name"-->
                                <!--                                    value-field="id"-->
                                <!--                                    placeholder="Select location"-->
                                <!--                                >-->
                                <!--                                  <template #first>-->
                                <!--                                    <b-form-select-option value="null" disabled hidden>Select location</b-form-select-option>-->
                                <!--                                  </template>-->
                                <!--                                </b-form-select>-->
                            </div>
                        </div>
                        <div v-if="isActiveParssearchCode" class="form-group row">
                          <label for="location" class="col-lg-3 col-form-label">Partssearch Shop Location</label>
                          <div class="col-lg-9" style="padding-top: 10px;">
                            <label
                                v-if="!isUsersEdit"
                                for="location"
                                class="col-form-label form-text">
                              <template v-if="selectedUser.partssearchLocationId">
                                {{getLocNameById(selectedUser.partssearchLocationId)}}
                              </template>
                              <template v-else-if="defaultPSLocId && !selectedUser.partssearchLocationId">
                                Default {{getLocNameById(defaultPSLocId)}}
                              </template>
                            </label>

                            <template v-else>
                              <multiselect
                                  v-if="isSetDefaultPSLoc"
                                  v-model="computedSelectedUserPSLoc"
                                  :options="locationsOptions"
                                  :showLabels="false"
                                  :option-height="29"
                                  :max-height="203"
                                  placeholder="Partssearch Shop Location"
                                  :close-on-select="true"
                                  track-by="id"
                                  label="name"
                              />
                              <span v-else>None</span>
                            </template>
                          </div>
                        </div>
                        <div class="form-group row">
                            <label for="work_phone" class="col-lg-3 col-form-label">Work Phone</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        for="work_phone"
                                        class="col-form-label form-text"
                                >{{ selectedUser.workPhone }}</label>
                                <input
                                        v-else
                                        type="text"
                                        class="form-control"
                                        v-model="selectedUser.workPhone"
                                        id="work_phone"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label for="mobile_phone" class="col-lg-3 col-form-label">Mobile
                                Phone</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        for="mobile_phone"
                                        class="col-form-label form-text"
                                >{{ selectedUser.mobilePhone }}</label>
                                <input
                                        v-else
                                        type="text"
                                        class="form-control"
                                        v-model="selectedUser.mobilePhone"
                                        id="mobile_phone"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label">Role</label>
                            <div class="col-lg-9">
                                <label
                                        v-if="!isUsersEdit"
                                        class="col-form-label form-text"
                                >{{ showRole(selectedUser.role) }}</label>
                                <multiselect
                                        v-else
                                        ref="repairer-user-role-options"
                                        v-model="selectedUser.roleValue"
                                        :options="roles"
                                        :showLabels="false"
                                        :label="'label'"
                                        :option-height="29"
                                        :max-height="203"
                                        :close-on-select="true"
                                        placeholder="Select Role"
                                />
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label">Last Logged In</label>
                            <div class="col-lg-9">
                                <label class="col-form-label form-text">{{ selectedUser.lastLoggedIn }}</label>
                            </div>
                        </div>
                        <div class="form-group row">
                            <label class="col-lg-3 col-form-label">Active sessions</label>
                            <div v-if="selectedUser.username" class="col-lg-9">
                                <label class="col-form-label form-text">{{ selectedUser.activeSessions }}</label>
                            </div>
                        </div>
                        <div class="form-group row action-btns" v-show="!isAddNewUserClicked">
                            <template v-if="isUsersEdit">
                                <button class="edit-btn float-right" @click="onClickCancelEditUser()">Cancel</button>
                                <button class="update-btn float-right" @click="onClickUpdateUser()">Update</button>
                            </template>
                            <template v-else>
                                <button class="delete-user float-right" @click="onClickRemoveUser()">
                                    Delete User
                                </button>
                                <button class="close-active-btn float-right" @click="onClickCloseActiveSessions()">
                                    Close Active Sessions
                                </button>
                            </template>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="isUsersEdit" class="update-password-section">
                <div class="section-header">
                    <div>Password</div>
                </div>

                <div class="form-body">
                    <div class="form-group row mt-2">
                        <label class="col-lg-4 col-form-label"
                               for="password_of_selected_user">Password</label>
                        <div class="col-lg-8">
                            <input
                                    type="password"
                                    class="form-control"
                                    v-model="selectedUser.password"
                                    :name="'selected_user_' + selectedUser.id"
                                    :readonly="!isStatusActive"
                                id="password_of_selected_user"
                            />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="confirm_password_of_selected_user" class="col-lg-4 col-form-label">Confirm
                            Password</label>
                        <div class="col-lg-8">
                            <input
                                    type="password"
                                    class="form-control"
                                    :readonly="!isStatusActive"
                                v-model="selectedUser.confirmPassword"
                                :name="'selected_user_confirm_' + selectedUser.id"
                                id="confirm_password_of_selected_user"
                            />
                        </div>
                    </div>

                    <div class="form-group row action-btns">
                        <button v-if="!isAddNewUserClicked" class="update-btn float-right"
                                @click="onClickUpdatePassword()">Update
                        </button>
                        <button v-else class="update-btn float-right" @click="onClickSaveUser()">Save</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import _ from "lodash";
    import Multiselect from 'vue-multiselect';
    import GoogleLocation from '../utility/google-location';
    import Email from '../utility/email-validator';
    import {mapGetters, mapActions} from "vuex";
    import vue2Dropzone from "vue2-dropzone";
    import "vue2-dropzone/dist/vue2Dropzone.min.css";
    import supplierMakes from "./SupplierMakes";
    import Axios from "axios";
    import {appConfig} from '../../config.js';

    export default {
        name: "Users",
        components: {
            GoogleLocation,
            supplierMakes,
            vueDropzone: vue2Dropzone,
            multiselect: Multiselect,
            Email,
        },
        props: {
          isActiveParssearchCode: {
            type: Boolean,
            default: false,
          },
          isSetDefaultPSLoc: {
            type: Boolean,
            default: false,
          },
          defaultPSLocId: {
            type: String,
            default: null,
          },
        },
        data() {
            return {
                locations: [],
                isCheckedSelectedUserEmail: true,
                isUploading: false,
                usersAll: [],
                isUsersEdit: false,
                isAddNewUserClicked: false,
                lastSelectedUser: {
                    id: '',
                    username: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    role: '',
                    address: '',
                    mobilePhone: '',
                    partssearchLocationId: '',
                    workPhone: '',
                    lastLoggedIn: '',
                    activeSessions: -1,
                    password: '',
                    confirmPassword: '',
                    roleValue: {}
                },
                selectedUser: {
                    id: '',
                    username: '',
                    firstName: '',
                    lastName: '',
                    email: '',
                    role: '',
                    address: '',
                    mobilePhone: '',
                    partssearchLocationId: '',
                    workPhone: '',
                    lastLoggedIn: '',
                    activeSessions: -1,
                    password: '',
                    confirmPassword: '',
                    roleValue: {}
                },
                usersAllFields: [
                    {
                        label: "Name",
                        key: "full_name",
                        tdClass: "name name-td clickable font-weight-bold",
                        thClass: "table-header"
                    },
                    {
                        label: "Role",
                        key: "role",
                        tdClass: "role role-td clickable",
                        thClass: "table-header"
                    },
                    {
                        label: "",
                        key: "actions",
                        tdClass: "actions clickable text-right",
                        thClass: "table-header"
                    }
                ],
                selectedUserAvatarDropdownOptions: {
                    headers: {Authorization: localStorage.getItem("token")},
                    resizeWidth: 400,
                    resizeQuality: 0.7,
                    url: 'url', // need to input dynamic url
                    method: "post",
                    maxFilesize: 5,
                    thumbnailWidth: 160,
                    thumbnailHeight: 160,
                    addRemoveLinks: false,
                    acceptedFiles: "image/jpeg, image/jpg, image/png",
                    dictFileTooBig:
                        "Your file has exceeded the maximum limit of 5MB per file. Please reduce the file size and upload again"
                }
            };
        },
        mounted() {
            this.loadLocations();
            this.loadUsers(1, (users) => {
                if (!_.isEmpty(users)) {
                    this.updateSelectedUser(users[0]);
                }
            });
        },
        computed: {
            ...mapGetters({
                currentUser: 'userInfo',
                roles: 'roles',
                isStatusActive: 'isStatusActive',
                getterPartsSearchLocations: 'getPartsSearchLocations',
          }),
          computedSelectedUserPSLoc: {
            get: function () {
              let vm = this;
              try {
                let item = _.find(this.locationsOptions, function (itm) {
                  return Number(itm.id) === Number(vm.selectedUser.partssearchLocationId);
                });
                return item;
              } catch (e) {
                return {};
              }
            },
            set: function (item) {
              if (item) {
                this.selectedUser.partssearchLocationId = item.id;
              }
            }
          },
          locationsOptions() {
            if (!this.getterPartsSearchLocations) {
              return  []
            }
            let locations = this.getterPartsSearchLocations.slice();
            locations.unshift({name: 'Default', id: null});
            return locations;
          },
        },
      methods: {
        ...mapActions({
            actionLoadUsers: 'loadUsers',
        }),
        getLocNameById(id) {
          let loc = _.find(this.locationsOptions, (lc) => {
            return Number(lc.id) === Number(id)
          })
          if (loc) {
            return loc.name;
          } else {
            return  '';
          }
        },
        loadLocations() {
          Axios.get('/fe/vendor/locations')
              .then(res => {
                if(res && res.data && res.data._status){
                  this.locations = res.data.locations;
                }
              })
              .catch(e => toastr.error(e));
            },
            getLocation(id) {
                let data = _.find(this.locations, (item) => {
                    return id === item.id;
                });
                if (_.isEmpty(data)) {
                    return {name: '', id:''};
                }
                return data;
            },
            showRole(item) {
                let role = _.find(this.roles, role => {
                    return role.value === item;
                });
                if (role) {
                    return role.label;
                }
                return '';
            },
            rowClass(item, type) {
                let result = '';

                if (item && item.id === this.selectedUser.id) {
                    result = 'selected-td';
                }
                return result;
            },
            loadUsers: function (pageNumber = 1, usersLoaded = (users) => {
            }) {
                NProgress.start();
                Axios.get('/fe/settings/users', {
                    params: {
                        pageNum: pageNumber,
                        sort_by: 'user_id',
                        sort_type: 'DESC'
                    }
                }).then(response => {
                    let users = _.map(response.data.data, item => {
                        item.id = Number(item.id);
                        return item;
                    });
                    users = _.sortBy(users, ['id']);
                    this.usersAll = users;
                    usersLoaded(users);
                    NProgress.done();
                }).catch(error => {
                    console.log(error);
                    NProgress.done();
                });
            },
            validateForm: function (withPassword = false) {
                let result = true;
                if (this.selectedUser.username === '') {
                    toastr.error('The Username must be specified');
                    result = false;
                } else if (this.selectedUser.first_name === '') {
                    toastr.error('The First Name must be specified');
                    result = false;
                } else if (this.selectedUser.last_name === '') {
                    toastr.error('The Last Name must be specified');
                    result = false;
                } else if (_.isEmpty(this.selectedUser.roleValue)) {
                    toastr.error('The Role must be specified');
                    result = false;
                } else if (this.selectedUser.new_password === '') {
                    toastr.error('The Password must be specified');
                    result = false;
                } else if (this.selectedUser.email === '') {
                    toastr.error('The Email must be specified');
                    result = false;
                } else if (withPassword) {
                    result = this.validateSelectedUserPassword();
                } else if (!this.isCheckedSelectedUserEmail) {
                    toastr.error('Please enter a valid email address');
                    result = false;
                }

                return result;
            },
            validateSelectedUserPassword: function () {
                let result = true;
                if (!this.selectedUser.password) {
                    toastr.error('Please input the password!');
                    result = false;
                }
                if (this.selectedUser.password !== this.selectedUser.confirmPassword) {
                    toastr.error('Passwords do not match!');
                    result = false;
                }
                return result;
            },
            onUsersRowClick: function (item) {
                this.updateSelectedUser(item);
                this.isUsersEdit = false;
                this.isAddNewUserClicked = false;
            },
            onClickAddNewUser: function () {
                this.isUsersEdit = true;
                this.isAddNewUserClicked = true;
                this.updateSelectedUser({});
            },
            onClickCancelEditUser: function () {
                if (this.isAddNewUserClicked) {
                    this.updateSelectedUser(this.lastSelectedUser);
                }
                this.isUsersEdit = false;
                this.isAddNewUserClicked = false;
            },
            onClickSaveUser: function () {
                if (!this.isStatusActive) {
              return;
            }
            this.addNewUser();
        },
        addNewUser: function () {
            if (!this.validateForm(true)) {
                return false;
            }
            let data = this.getFormData(this.selectedUser);

                if (!this.isCheckedSelectedUserEmail) {
                    toastr.error('Your changes could not be saved, please check the form fields again');
                    return;
                }
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/users/new`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: data
                })
                    .then(response => {
                        if (response.status === 200) {
                            toastr.success('New user created!');
                            this.isUsersEdit = false;
                            this.isAddNewUserClicked = false;
                            this.actionLoadUsers();
                            this.loadUsers(1, (users) => {
                                if (!_.isEmpty(users)) {
                                    this.updateSelectedUser(users[users.length - 1]);
                                }
                            });
                            return;
                        } else {
                            toastr.error(response.data.msg);
                        }
                    })
                    .catch(error => {
                        toastr.error(response.data.msg);
                    });
            },
            updateUser: function (user, withPassword = false) {
                if (!this.isStatusActive) {
              return;
            }
            if (!this.validateForm(withPassword)) {
                    return false;
                }

                let data = this.getFormData(user);

                NProgress.start();
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: `/fe/users/${user.id}`,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: data
                }).then(response => {
                    if (response.status === 200) {
                        if (response.data && response.data._status && response.data._status === true) {
                            if (response.data.update) {

                                if (response.data.update.active == 0) {
                                    toastr.success('The user status has been changed to Not Active');
                                }
                                if (response.data.update.active == 1) {
                                    toastr.success('The user status has been changed to Active"');
                                }

                                if (response.data.update.data) {
                                    toastr.success('The user info has been updated!');
                                }
                                if (response.data.update.password) {
                                    toastr.success('The user password has been changed!');
                                }
                            }
                        }

                        this.selectedUser.password = "";
                        this.selectedUser.confirmPassword = "";
                        this.actionLoadUsers();
                        this.loadUsers(1, (users) => {
                            if (!_.isEmpty(users)) {
                                this.updateSelectedUser(_.find(users, user => {
                                    return user.id === this.selectedUser.id;
                                }));
                            }
                        });
                    } else {
                        toastr.error(response.data.msg);
                    }
                    NProgress.done();
                }).catch(error => {
                    toastr.error(response.data.msg);
                    NProgress.done();
                });
            },
            getFormData(user) {
                let data = {
                    id: user.id,
                    email: user.email,
                    first_name: user.firstName,
                    last_name: user.lastName,
                    address: user.address,
                    work_phone: user.workPhone,
                    mobile_phone: user.mobilePhone,
                    username: user.username,
                    role: user.roleValue.id,
                    password: user.password,
                    is_active: user.isActive,
                    avatarFileId: user.avatarFileId,
                    partssearchLocationId: user.partssearchLocationId,
                };
                return data;
            },
            onClickUpdateUser: function () {
                if (!this.isStatusActive) {
                  return;
                }
                if (this.selectedUser.address && this.selectedUser.address.id) {
                    this.selectedUser.address = this.selectedUser.address.id;
                }
                this.updateUser(this.selectedUser);
                this.isUsersEdit = false;
                this.isAddNewUserClicked = false;
            },
            setUploadUrlForUsersPhoto: function (id) {
                if (!_.isEmpty(this.$refs.imgUpDropUsers)) {
                    let url = appConfig.baseAPIURL + "/fe/users/" + id + "/photo/upload";
                    this.$refs.imgUpDropUsers.setOption('url', url);
                }
            },
            onUserEdit: function (item) {
                if (!this.isStatusActive) {
                  return;
                }
                if (!_.isEmpty(item.address)) {
                    let address = _.find(this.locations, (location) => {
                        return item.address == location.id;
                    });
                    item.address = address;
                }
                this.updateSelectedUser(item);
                this.isUsersEdit = true;
                this.isAddNewUserClicked = false;
            },
            onClickRemoveUser: function (item) {

                if (!this.isStatusActive) {
                  return;
                }
                let userId = (!item) ? this.selectedUser.id : item.id;

                NProgress.start();
                Axios.post('/fe/users/' + userId + '/delete', {delete: userId})
                    .then(response => {
                        if (response.status !== 200) {
                            toastr.error(response.data.msg);
                        } else {
                            toastr.success(response.data.msg);
                            this.actionLoadUsers();
                            this.loadUsers(1);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        console.log(error);
                        NProgress.done();
                    });
                this.updateSelectedUser({});
            },
            onClickCloseActiveSessions: function () {
                if (!this.isStatusActive) {
              return;
            }
            NProgress.start();
            Axios.post('/fe/users/' + this.selectedUser.id + '/closeActiveSessions')
                .then(response => {
                    toastr.success('Active sessions have been closed');
                    this.actionLoadUsers();
                    this.loadUsers(1);
                })
                .catch(error => {
                    toastr.error('Active sessions have not been closed');
                    console.log(error);
                }).finally(()=>{
                    NProgress.done();
                });
                this.isUsersEdit = false;
                this.isAddNewUserClicked = false;
            },
            onClickUpdatePassword: function () {
                if (!this.isStatusActive) {
              return;
            }
            this.updateUser(this.selectedUser, true);
        },
        onUnlockPassword: function () {
        },
        onClickDeletePhoto() {
            if (!this.isStatusActive) {
              return;
            }

            this.selectedUser.userPhoto = '';

                Axios.post('/fe/users/' + this.selectedUser.id + '/photo/delete')
                    .then(response => {
                        toastr.success('User photo has been removed');
                        this.loadUsers(1);
                        NProgress.done();
                    }).catch(error => {
                    toastr.error('User photo has not been removed');
                    console.log(error);
                    NProgress.done();
                });
            NProgress.start();
        },
        updateSelectedUser: function (data) {
            if (!_.isEmpty(data)) {
                this.setUploadUrlForUsersPhoto(data.id);
                this.selectedUser = {
                    id: data.id,
                    username: data.username,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    email: data.email,
                    role: data.role,
                    isActive: data.isActive,
                    address: data.address,
                    userPhoto: data.avatar,
                    partssearchLocationId: data.partssearchLocationId,
                    mobilePhone: data.mobilePhone,
                    workPhone: data.workPhone,
                    activeSessions: data.activeSessions,
                    lastLoggedIn: data.lastLoggedIn,
                    roleValue: _.find(this.roles, role => { return role.value === data.role;
                        })
                    };
                } else {
                    this.selectedUser = {
                        id: '',
                        username: '',
                        firstName: '',
                        lastName: '',
                        email: '',
                        role: '',
                        isActive: true,
                        address: '',
                        userPhoto: '',
                        mobilePhone: '',
                        workPhone: '',
                        lastLoggedIn: '',
                        activeSessions: '',
                        partssearchLocationId: '',
                        password: '',
                        confirmPassword: '',
                        roleValue: {}
                    };
                }
            },
            //Images
            addImgUpload($event) {
                if (!this.isStatusActive) {
              return;
            }
            $event.previewElement.children[
            $event.previewElement.children.length - 1
                ].style.display = "none";
        },
        completeImgUpload($event) {
            if (!this.isStatusActive) {
              return;
            }
            $event.previewElement.children[
            $event.previewElement.children.length - 1
                ].removeAttribute("style");
        },
        sendImgUpload($event) {
            if (!this.isStatusActive) {
              return;
            }
            this.isUploading = true;
            $event.previewElement.children[
            $event.previewElement.children.length - 1
                ].style.display = "none";
        },
        successImgUploadSelectedUser(file, response) {
            if (!this.isStatusActive) {
              return;
            }
                this.isUploading = false;
                if (response.status) {
                    this.$set(this.selectedUser, 'userPhoto', file.dataURL);
                    this.selectedUser.avatarFileId = response.photoId;
                    this.updateUser(this.selectedUser);
                } else {
                    this.$set(this.selectedUser, 'userPhoto', null);
                }
                setTimeout(() => {
                    this.$refs.imgUpDropUsers.removeFile(file);
                }, 1000);

            }
        }
    };
</script>

<style>


</style>
