<script>
import draggable from 'vuedraggable'
import imageGallery from './image-gallery'
import { isMobile } from '../../../deviceDetect/index'
import { VueContext } from 'vue-context'
import Axios from 'axios'
import { mapGetters } from 'vuex'
import _ from 'lodash'

export default {
  name: 'image-lightbox',
  props: [
    'fromComponent',
    'imageList',
    'path',
    'position',
    'isPreviewImage',
    'isDelete',
    'showContextMenu',
    'boardInId',
    'boardDepartureId',
    'isAlreadyShowGallery',
    'isShowBigImage',
  ],
  data: function () {
    return {
      isMobile,
      shownGallery: null,
      galleryImages: [],
      lastVerions: {},
      drag: false,
    }
  },
  computed: {
    ...mapGetters({
      isCustomerUser: 'isCustomerUser',
      isStatusActive: 'isStatusActive',
    }),
    isFromEstimate: function () {
      return this.fromComponent && this.fromComponent === 'estimate'
    },
    isFromCard: function () {
      return this.fromComponent && this.fromComponent === 'card'
    },
    categories: function () {
      return Object.keys(this.imageList)
    },
  },
  methods: {
    openContextMenu(event, img) {
      if ((!this.boardInId && !this.boardDepartureId) && !(this.showContextMenu)) {
        return;
      }
      this.$refs.menuForImage.open(event, { img: img })
    },
    openInNewTab(data) {
      window.open(
          window.location.href + '/img/' + data.img.id,
          "_blank"
      );
      this.$refs.menuForImage.close()
    },
    openInNewWindow(data) {
      window.open(
          window.location.href + '/img/' + data.img.id,
          "_blank",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=" +
          screen.width +
          ",height=" +
          screen.height
      );
      this.$refs.menuForImage.close()
    },
    changeImageByDraggable (event, category) {
      // console.log('changeImageByDraggable.start', event, category)
      if (!event || !category) {
        return
      }
      if (event.removed) {
        // console.log('changeImageByDraggable.removed')
        return
      }
      let element = null
      let update = {}
      if (event.added && event.added.element) {
        element = event.added.element
        update.imgInfo = element
        update.categoryName = category
      }
      // else{
      //   update.imgInfo.id = null;
      //   update.categoryName=category;
      // }
      var sort = []
      if (this.imageList[category]) {
        _.forEach(this.imageList[category], function (el, index) {
          console.log('el', el)
          sort.push({ id: el.id, index: index + 1 })
        })
      }
      update.imagesSort = sort
      // console.log('changeImageByDraggable.update',update)
      this.$emit('updateCategoryFunc', update)
      // update.sort.push({sort: sort, card: card});
    },
    imgLog (event) {
      console.log('imgLog', event)
    },
    addedToVehicleInBoard (e, data) {
      if (!data.img.addedToBoardIn && this.boardInId) {
        Axios({
          method: 'post',
          responseType: 'json',
          url: `/fe/board/add-image`,
          validateStatus: function (status) {
            return status < 500
          },
          data: {
            boardId: this.boardInId,
            imageId: data.img.id,
          },
        }).then(response => {
          if (response.status === 200 && response.data._status) {
            toastr.success('Image added successfully to the Vehicle In Board')
          } else {
            toastr.error(response.data.msg)
          }
        })
            .catch(error => {
              toastr.error(error)
            })
            .finally(() => {
              NProgress.done()
            })
      }
    },
    addedToDepartureBoard (e, data) {
      if (!data.img.addedToBoardDeparture && this.boardDepartureId) {
        Axios({
          method: 'post',
          responseType: 'json',
          url: `/fe/board/add-image`,
          validateStatus: function (status) {
            return status < 500
          },
          data: {
            boardId: this.boardDepartureId,
            imageId: data.img.id,
          },
        }).then(response => {
          if (response.status === 200 && response.data._status) {
            toastr.success('Image added successfully to the Departure Board')
          } else {
            toastr.error(response.data.msg)
          }
        })
            .catch(error => {
              toastr.error(error)
            })
            .finally(() => {
              NProgress.done()
            })
      }
    },
    imagesByCategory: function (category) {
      return this.imageList[category]
    },
    makeImageUrl: function (img) {
      return img.src.lastIndexOf('data', 0) === 0 ? img.src : this.path + img.src
    },
    makeImagePreviewUrl: function (img) {
      return img.previewW220
      // const versionPart = this.lastVerions[img.id.toString()] ? '?version=' + this.lastVerions[img.id.toString()] : '' //ToDO need review from old code
      // return (img.previewH240.lastIndexOf('data', 0) === 0 ? img.previewH240 : this.path + img.previewH240) + versionPart
    },
    recoverToUpperCase: function (item) {
      return item.replace(/_/g, ' ').split(' ').map(_ => _.replace(_.charAt(0), _.charAt(0))).join(' ')
    },

    isDraggedIMG: function (evt) {
      // console.log('isDraggedIMG', evt);
      if (evt.to.classList.contains('no_images_drag_area') && evt.to.children.length) {
        evt.to.classList.remove('no_images_drag_area')
      }
      if (!evt.from.classList.contains('no_images_drag_area') && evt.from.children.length === 0) {
        evt.from.classList.add('no_images_drag_area')
      }
      this.drag = false
      // let getAttr = evt.item.getAttribute('data-itemArray').split(',')
      // let itemInfo = this.imageList[getAttr[1]].filter(e => e.id === getAttr[0])
      //  this.$emit('updateCategoryFunc', {imgInfo: (itemInfo) ? itemInfo[0] : null, categoryName: evt.to.getAttribute('data-status-wrap')}) //moved into changeImageByDraggable function
    },
    onClickRemove: function (data) {
      this.$emit('removeImage', data) // { key: string, id: string }
    },
    setGallery(v) {
      let vm=this
      if (_.isEqual(v.images, vm.shownGallery.images), _.isEqual(v.category, vm.shownGallery.category)) {
        vm.$nextTick(() => {
          vm.$set(vm.shownGallery, 'imageIndex', v.imageIndex)
          vm.$refs['image-gallery'].setIndex(v.imageIndex)
        })
      } else {
        return
        vm.shownGallery = null
        vm.$nextTick(() => {
          vm.shownGallery = v
        })
      }
    },
    onClickImage: function (index, category) {
let vm=this
      const images = category ? this.imageList[category] : this.imageList
      const imagesData = images.map(p => { return { id: p.id, url: this.makeImageUrl(p) } })

      if (this.position == 'pin_images' && this.isAlreadyShowGallery && (this.isFromEstimate || this.isFromCard)) {
       this.$emit('selectImageGallery', {
         category: category,
         imageIndex: index,
         images: imagesData
       })
        return
      }

      // reset current
      this.onCloseGallery()

      // set after finish reset
      vm.$nextTick(() => {
        vm.$emit('showOrHideGallery', true)
        vm.shownGallery = {
          category: category,
          imageIndex: index,
          images: imagesData
        }
      })
    },
    onCloseGallery: function () {
      this.$emit('showOrHideGallery', false)
      this.shownGallery = null
    },
    onImageSaved: function (id) {
      this.lastVerions[id.toString()] = (new Date()).getTime().toString()
    }
  },
  mounted: function () {
  },
  destroyed: function () {
  },
  watch: {
    // main: to update image gallery whenever imageList change
    // for example, an image deleted
    imageList () {
      let vm= this
      if (this.$route.params.img_id) {
        _.forIn(this.imageList, (images, category) => {
          let imageIndex = _.findIndex(images, (img) => {
            return Number(img.id) == Number(this.$route.params.img_id)
          })
          if (imageIndex !== -1) {
            const images = category ? this.imageList[category] : this.imageList
            const imagesData = images.map(p => { return { id: p.id, url: this.makeImageUrl(p) } })

            vm.$nextTick(() => {
              vm.$emit('showOrHideGallery', true)
              vm.shownGallery = {
                category: category,
                imageIndex: imageIndex,
                images: imagesData
              }
            })
          }
        })
      }

      // when gallery open, update it
      if (this.shownGallery) { // it is null when gallery not open
        // select other image to show on gallery
        const currentIndex = this.shownGallery.imageIndex
        const category = this.shownGallery.category
        const countImages = this.imageList[category].length

        // no image left, close the gallery
        if (!countImages) {
          // console.log('close gallery')
          this.$refs['image-gallery'].onClickClose()
          return
        }

        if (currentIndex <= countImages - 1) {
          // console.log('choose next image')
          this.onClickImage(currentIndex, category)
        } else if (currentIndex > countImages - 1) {
          // console.log('choose prev image')
          this.onClickImage(currentIndex - 1, category)
        } else {
          // console.log('last, choose first image')
          this.onClickImage(0, category)
        }
      }
    }
  },
  components: {
    draggable: draggable,
    'image-gallery': imageGallery,
    VueContext,

  }
}
</script>

<template>
  <div :class="position">
    <image-gallery v-if="shownGallery"
                   ref="image-gallery"
                   :use-editor="true"
                   :images="shownGallery.images"
                   :isShowBigImage="isShowBigImage"
                   :category="shownGallery.category"
                   :current-index="shownGallery.imageIndex"
                   :show-remove='true'
                   @removeImage="onClickRemove"
                   @close-gallery="onCloseGallery"
                   @change="index => shownGallery.imageIndex = index"
                   @saved-image="onImageSaved">
    </image-gallery>
    <template v-if="(isFromEstimate || isFromCard)">
      <div v-for="category in categories" class="each_row_images img-grid"
           :class="{'' : position === 'below_drag_area', is_empty_block: imageList[category].length === 0, }">
        <div class="categories_title"><span class="text-title-categories">{{ recoverToUpperCase(category) }}</span>
        </div>
        <span v-if="imageList[category].length === 0"
              :class="imageList[category].length !== 0 ? 'hidden-area-empty-images' : 'area-empty-images'">Empty</span>
        <draggable
            :class="{'no_images_drag_area': imageList[category].length === 0}"
            draggable=".item_drag"
            :disabled="!isStatusActive || isMobile"
            :options="{
            animation:200,
            group:'imgList',
            delay: isMobile ? 400 : false,
          }"
            :move="imgLog"
            @start="drag=true"
            @end="isDraggedIMG"
            @change="changeImageByDraggable($event, category)"
            :list="imageList[category]"
            class="draggable-images rs-scroll"
        >
          <div v-for="(img, ind) in imageList[category]" :key="img.id" draggable="false"
               class="item_div_drag item_drag col-4">
            <div v-if="img.src" class="box box-block bg-grey mb-1">
              <div class="row icon-action-block" :class="{'icon-action-block-width-45': !isDelete}">
                <button @click="onClickImage(ind, category)"
                        class="action-button" type="button"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip--bottomleft', html: true, title:'Enlarge image', placement:'bottomleft'}"
                        style="margin-right: 4px;">
                  <svg class="enlarge-icon" fill="currentColor" width="20px" height="20px" viewBox="0 0 20 12"
                       version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                       style="pointer-events: none">
                    <title></title>
                    <desc></desc>
                    <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Dashboard-Redesign-(Detail-Cards)Images" transform="translate(-462.000000, -509.000000)"
                         fill="currentColor" fill-rule="nonzero">
                        <g id="Group-3" transform="translate(265.000000, 427.000000)">
                          <g id="Uncategorised">
                            <g id="Group-6" transform="translate(0.000000, 63.000000)">
                              <g id="Group-5" transform="translate(184.000000, 5.000000)">
                                <g id="eye" transform="translate(13.000000, 14.000000)">
                                  <path
                                      d="M18,6 C18,4.19 14.24,2.015 9.993,1.99992347 C5.775,1.985 2,4.178 2,6 C2,7.825 5.754,10.006 9.997,10.0000124 C14.252,9.994 18,7.82 18,6 Z M10,12.0000136 C4.958,12.007 0,9.314 0,6 C0,2.686 4.984,-0.017 10,-7.97519788e-05 C15.016,0.017 20,2.686 20,6 C20,9.314 15.042,11.993 10,12.0000136 Z M10,10 C7.790861,10 6,8.209139 6,6 C6,3.790861 7.790861,2 10,2 C12.209139,2 14,3.790861 14,6 C14,8.209139 12.209139,10 10,10 Z M10,8 C11.1045695,8 12,7.1045695 12,6 C12,4.8954305 11.1045695,4 10,4 C8.8954305,4 8,4.8954305 8,6 C8,7.1045695 8.8954305,8 10,8 Z"
                                      id="Shape"></path>
                                </g>
                              </g>
                            </g>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
                <button v-if="isDelete && isStatusActive" @click="$emit('removeImage', {key:category, id:img.id})"
                        class="action-button"
                        type="button"
                        v-b-tooltip.hover="{customClass: 'ps-tooltip ps-tooltip--bottomleft', html: true, title:'Remove this image', placement:'bottomleft'}">
                  <svg width="18px" height="20px" viewBox="0 0 18 20" version="1.1" xmlns="http://www.w3.org/2000/svg"
                       xmlns:xlink="http://www.w3.org/1999/xlink" style="pointer-events: none">
                    <title></title>
                    <desc></desc>
                    <g id="UI-Design" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                      <g id="Dashboard-Redesign-(Detail-Cards)Files" transform="translate(-1264.000000, -933.000000)"
                         fill="#FF5E5E" fill-rule="nonzero">
                        <g id="Group-4" transform="translate(265.000000, 933.000000)">
                          <g id="trash" transform="translate(999.000000, 0.000000)">
                            <path
                                d="M6,2 L6,1 C6,0.44771525 6.44771525,0 7,0 L11,0 C11.5522847,0 12,0.44771525 12,1 L12,2 L16,2 C17.1045695,2 18,2.8954305 18,4 L18,5 C18,6.1045695 17.1045695,7 16,7 L15.867,7 L15.187,17.2 C15.081727,18.775582 13.773095,20 12.194,20 L5.826,20 C4.24834545,20 2.94028051,18.7780028 2.833,17.204 L2.137,7 L2,7 C0.8954305,7 0,6.1045695 0,5 L0,4 C0,2.8954305 0.8954305,2 2,2 L6,2 Z M16,4 L2,4 L2,5 L16,5 L16,4 Z M4.141,7 L4.828,17.068 C4.86376888,17.5927957 5.29998676,18.0001655 5.826,18 L12.194,18 C12.7207338,18 13.1572356,17.5915853 13.192,17.066 L13.862,7 L4.142,7 L4.141,7 Z M7,8 C7.55228475,8 8,8.44771525 8,9 L8,16 C8,16.5522847 7.55228475,17 7,17 C6.44771525,17 6,16.5522847 6,16 L6,9 C6,8.44771525 6.44771525,8 7,8 Z M11,8 C11.5522847,8 12,8.44771525 12,9 L12,16 C12,16.5522847 11.5522847,17 11,17 C10.4477153,17 10,16.5522847 10,16 L10,9 C10,8.44771525 10.4477153,8 11,8 Z"
                                id="Shape"></path>
                          </g>
                        </g>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
              <a v-if="isPreviewImage" draggable="false" @click="onClickImage(ind, category)"
                 class="hand-pointer img-grid__link ttt1">
                <img @contextmenu.prevent="openContextMenu($event, img)"
                     :src="makeImagePreviewUrl(img)" draggable="true" class="wrap img-fluid w-100">
              </a>
              <a v-else @click="onClickImage(ind, category)" draggable="false" class="hand-pointer img-grid__link ttt2">
                <img @contextmenu.prevent="openContextMenu($event, img)" :src="makeImageUrl(img)"
                     draggable="true" class="wrap img-fluid w-100 ">
              </a>
            </div>
          </div>
        </draggable>
      </div>
    </template>
    <template v-else-if="!shownGallery">
      <div v-for="(img, index) in imageList" class="col-sm-3">
        <div v-if="img" class="box bg-grey img-box">
          <button v-if="isStatusActive" @click="onClickRemove(index)" class="delete" type="button"><i class="ti-trash"
                                                                                                      title="Remove this image"></i>
          </button>
          <a @contextmenu.prevent="$refs.menuForImage.open($event, { img: img })" class="hand-pointer img-grid__link"
             @click="onClickImage(index, null)">
            <img :src="makeImageUrl(img)">
          </a>
        </div>
      </div>
    </template>

    <vue-context v-if="showContextMenu" ref="menuForImage" v-slot="{ data }">
      <ul>
        <li v-if="!isCustomerUser && (boardInId || boardDepartureId)" class="hasSubContext">
          Add to
          <i class="icon-right bx bx-chevron-right"></i>
          <ul type="none" class="context-level subContext ">
            <li v-if="boardInId" @click.prevent="addedToVehicleInBoard($event, data)">Vehicle In Board</li>
            <li v-if="boardDepartureId" @click.prevent="addedToDepartureBoard($event, data)">Departure Board</li>
          </ul>
        </li>
        <li @click="openInNewWindow(data)">
          Open in new Window
        </li>
        <li @click="openInNewTab(data)">
          Open in new Tab
        </li>
      </ul>
    </vue-context>

  </div>
</template>

<style scoped>
.v-context .context-level {
  width: 117px;
  left: 139px;
  top: -1px;
}

.hand-pointer {
  display: block;
  cursor: pointer;
}

.V3 .below_drag_area {
  display: flex;
  flex-flow: row nowrap;

  width: 100%;
  /* height: 70vh; */
  white-space: nowrap;
  overflow: scroll;

  scrollbar-width: thin;
  scrollbar-color: #b7b8c0; /* #5e79ff; */
}

.V3 .below_drag_area::-webkit-scrollbar {
  width: unset;
  height: 7px;
}

.V3 .below_drag_area::-webkit-scrollbar-thumb {
  background-color: #b7b8c0; /* #5e79ff; */
}

.V3 .below_drag_area .box {
  text-align: center;
}

.V3 .below_drag_area .box:hover .icon-action-block {
  opacity: 1;
}

.V3 .image-pin-area .icon-action-block {
  display: none;
}

.V3 .below_drag_area .box img {
  width: 100%;
  border-radius: 3px;
  height: 169px;
  object-fit: cover;
}

.V3 .below_drag_area .img-box {
  position: relative;
  width: 100%;
  height: 250px;
}

.V3 .below_drag_area .img-box a {
  display: inline-block;
  height: 250px;
}

.V3 .below_drag_area .img-box a img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.V3 .below_drag_area .each_row_images > div .item_div_drag {
  width: 100%;
}

@media screen and (max-width: 992px) and (min-width: 576px) {
  .V3 .below_drag_area .each_row_images > div .item_div_drag {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .V3 .below_drag_area .each_row_images > div .item_div_drag {
    width: 100%;
  }
}

@media screen and (max-width: 768px) {
  .V3 .below_drag_area .each_row_images {
    flex: 1 0 250px !important;
  }

  .V3 .below_drag_area .draggable-images {
    flex-wrap: nowrap !important;
    flex-direction: column !important;
    justify-content: flex-start !important;
  }

  .V3 .below_drag_area .draggable-images::-webkit-scrollbar {
    width: 0;
  }

  .V3 .below_drag_area .draggable-images .col-4 {
    max-width: 100% !important;
  }
}

.V3 .below_drag_area .each_row_images {
  flex: 1 0 728px;

  margin: 15px 0;
  padding: 0 15px;
  border-right: 1px solid lightgray;
  width: 236px;
  display: inline-block;
  text-align: center;
}

.V3 .below_drag_area .each_row_images.is_empty_block {
  flex: 1 0 250px;
}

.V3 .each_row_images:last-child {
  border-right: none;
}

.V3 .below_drag_area .categories_title {
  height: 3rem;
  text-align: start;
  padding-top: 10px;
  border-radius: 3px;
  background-color: rgba(27, 30, 56, 0.12);
}

.V3 .below_drag_area .area-empty-images {
  opacity: 0.25;
  color: #1C1F39;
  font-family: "Nunito Sans";
  font-size: 13px;
  font-weight: 700;
  line-height: 18px;
  margin: auto;
}

.V3 .below_drag_area .hidden-area-empty-images {
  display: none;
}

.V3 .below_drag_area .no_images_drag_area {
  height: 42rem;
  margin: 1rem 0;
  text-align: center;
  padding-top: 82px;
}

.V3 .below_drag_area .draggable-images {
  width: 100%;
  height: 61.8vh;
  overflow: scroll;
  margin: 1rem 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
  overflow-x: hidden;
}

.V3 .below_drag_area .draggable-images .col-4 {
  flex: 0 0 33.333333%;
  max-width: 32%;
  position: relative;
  /*width: 100%;*/
  width: 32%;
  padding-left: 0;
  padding-right: 0;
  margin-right: 4px;
  margin-left: 4px;
}

.V3 .below_drag_area .draggable-images .col-4 .box-block {
  margin: 0 0 10px 0;
}

.V3 .below_drag_area .text-title-categories {
  margin: 1rem;
  font-family: "Nunito Sans";
  font-weight: 700;
  font-size: 13px;
  line-height: 18px;
  text-transform: unset;
  color: #1C1F39;
  opacity: 1;
}

.V3 .text-title-categories {
  text-transform: uppercase;
  opacity: 0.4;
}

.V3 .below_drag_area .icon-action-block-width-45 {
  width: 45px !important;
}

.V3 .below_drag_area .icon-action-block {
  position: absolute;
  /*display: block;*/
  opacity: 0;
  transition: opacity 250ms cubic-bezier(0.0, 0.0, 0.2, 1);
  height: 40px;
  width: 90px;
  top: 5px;
  right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  overflow: hidden;
}

.V3 .below_drag_area .icon-action-block i {
  font-size: 20px;
  font-weight: bold;
}

.V3 .below_drag_area .action-button {
  height: 40px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}

.V3 .below_drag_area .action-button:first-child {
  /* border-radius: 3px 0 0 3px; */
}

.V3 .below_drag_area .action-button:last-child {
  /* border-radius: 0 3px 3px 0; */
}

.V3 .below_drag_area .action-button:hover {
  cursor: pointer;
  background-color: #f8f8f8; /* rgba(94, 121, 255, 1); */
}

.V3 .below_drag_area .action-button:hover .enlarge-icon {
  color: #5E79FF;
}

.V3 .below_drag_area .action-button:first-child {
  border-right: 1px solid rgba(27, 30, 56, 0.25);
}
.sortable-ghost {
  opacity: 0;
}
</style>
<style>
.image-pin-area .categories_title {
  color: #fff;
  margin-bottom: 8px;
}
/* img-grid           */
/* some parts from cl */
.img-grid__link {
  cursor: pointer;
  box-sizing: border-box;
}

.each_row_images:last-child {
  padding-right: 0px !important;
}
</style>
