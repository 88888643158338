import _ from 'lodash';

export const Getters = {
  getPromos: state => {
    return state.promos;
  },
  getNews: state => {
    return state.news;
  },
  getTeam: state => {
    return state.team;
  },
  getEmails: state => {
    return _.filter(state.emails, (e) => {
      return !e.isViewed;
    })
  },
  getSms: state => {
    return state.sms;
  },
  countNewEmails: (state, getters, rootState, rootGetters) => {
    return rootGetters['dashboard/getEmails'].length;
  },
  getTasks: state => {
    return state.tasks;
  },
  getComments: state => {
    return state.comments;
  },
  getShowCountTasks: state => {
    return state.showCountTasks;
  },
  getShowCountCards: state => {
    return state.showCountCards;
  },
  getShowCountComments: state => {
    return state.showCountComments;
  },
  getShowCountEmails: state => {
    return state.showCountEmails;
  },
  getShowCountSms: state => {
    return state.showCountSms;
  },
  getShowOldTasks: state => {
    return state.showOldTasks;
  },
  getShowOldComments: state => {
    return state.showOldComments;
  },
  getShowOldEmails: state => {
    return state.showOldEmails;
  },
  getIsShowLastTasks: state => {
    return state.isShowLastTasks;
  },
  getIsShowLastComments: state => {
    return state.isShowLastComments;
  },
  getIsShowLastEmails: state => {
    return state.isShowLastEmails;
  },
}
