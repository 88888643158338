<script>
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-tabs */
import DatePicker from 'v-calendar/src/components/DatePicker'

export default {
  name: 'date-picker',
  props: {
    disabledDates: {
      type: Object,
      default() {
        return {}
      }
    },
    visibility: {
      type: String,
      default: 'click'
    },
    placeHolderText: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    value: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    isReadonly: {
      type: Boolean,
      default: false
    },
  },
  components: {
    DatePicker,
  },
  methods: {
    updateData (val) {
      this.$emit('input', val)
      this.$emit('onChange', {prev: this.localValue, now: val})
      this.localValue = val
    },
    _isEmpty(v) {
      return _.isEmpty(v)
    }
  },
  computed: {
    computedValue: {
      get () {
        return this.value
      },
      set (val) {
        return val
      }
    }
  },
  data () {
    return {
      masks: {
        input: 'DD/MM/YYYY',
      },
      modelConfig: {
        type: 'string',
        mask: 'DD/MM/YYYY'
      },
      localValue: this.value
    }
  },
}

</script>

<template>
  <DatePicker v-model="computedValue"
              :disabled-dates='_isEmpty(disabledDates) ? null : disabledDates'
              :attributes="[{key: 'today',highlight: !computedValue,dates: new Date(),},]"
              :masks="masks" :model-config="modelConfig" @input="updateData" :popover="{ visibility: visibility }">
    <template v-slot="{ inputValue, inputEvents }">
      <input
          class="form-control"
          :value="inputValue"
          v-on="inputEvents"
          :disabled="isDisabled"
          @keydown.down="$emit('onKeyPressDown')"
          @keydown.up="$emit('onKeyPressUp')"
          :readonly="isReadonly"
          :placeholder="placeHolderText"
          @change="updateData(inputValue)"
      />
    </template>
  </DatePicker>
</template>
