<script>
    import Axios from 'axios';
    import {mapGetters} from "vuex";
    import BlockPagination from '../utility/block-pagination';

    export default {
      name: 'invoice-notes',
      props: ['notes', 'invoice_id'],
      data: function () {
            return {
                note: '',
                note_id: null,
                filter: '',
                fields:[
                    {
                        label: "Note Details",
                        key: "id",
                        sortable: true,
                        tdClass: 'card-note'
                    },
                    {
                        label: "Created By",
                        key: "createdBy",
                        sortable: true,
                        tdClass: 'created-by'
                    },
                    {
                        label: "Date",
                        key: "createdOn",
                        sortable: true,
                        tdClass: 'created-on'
                    },
                    {
                        label: "Action",
                        key: "action",
                        tdClass: 'action'
                    }
                ],
                cardsPerPage: 10,
                cardsCurrentPage: 1,
            };
        },
      methods: {
            loadNotes() {
                this.$emit('loadNotes');
            },
            addNote: function () {
                if (!this.isStatusActive) {
                  return;
                }
                this.note_id = null;
                this.note = '';
            },
            editNote: function (note) {
                if (!this.isStatusActive) {
                  return;
                }
                this.note_id = note.id;
                this.note = note.note;
                this.note = this.note.replace(/<br \/>/g, "");
            },
          removeNote: function (note) {
              if (!this.isStatusActive) {
                return;
              }
              NProgress.start();
              if (note.id == null) {
                    return;
              }
              let url = `/fe/invoice/notes/${note.id}/delete`;
              Axios({
                  method: 'post',
                  responseType: 'json',
                  url: url,
                  validateStatus: function (status) {
                      return status < 500;
                  },
                  data: {
                    note_id: note.id
                  }
              })
                  .then(response => {
                      if (response.status == 200) {
                          toastr.success('Note has been deleted');
                          this.loadNotes();
                      } else {
                          toastr.error(response.data.msg);
                      }
                      NProgress.done();
                  })
                  .catch(error => {
                      NProgress.done();
                      toastr.error(error);
                  });
          },
            saveNote: function () {
                if (!this.isStatusActive) {
                  return;
                }
                NProgress.start();
                let invoice_id = this.invoice_id;
                let url = '';

                if (this.note_id == null) {
                    url = `/fe/invoice/${this.invoice_id}/notes/add`;
                } else {
                    url = `/fe/invoice/notes/${this.note_id}/update`;
                }
                Axios({
                    method: 'post',
                    responseType: 'json',
                    url: url,
                    validateStatus: function (status) {
                        return status < 500;
                    },
                    data: JSON.stringify({note: this.note, invoice_id: invoice_id})
                })
                    .then(response => {
                        if (response.status == 200 && response.data._status) {
                          toastr.success('Note saved');
                            // if (this.note_id == null) {
                            //     this.note_id = response.data.note.id;
                            // }
                            this.loadNotes();
                        } else {
                            toastr.error(response.data.msg);
                        }
                        NProgress.done();
                    })
                    .catch(error => {
                        NProgress.done();
                        toastr.error(error);
                    });
            },
        },
      computed: {
          ...mapGetters({
            isStatusActive: 'isStatusActive',
          }),
          totalNotes: function () {
              return this.notes.length;
          },
          fromNotes: function() {
              if (this.totalNotes == 0){
                  return 0;
              }
              const fromNotes = (this.cardsCurrentPage - 1) * this.cardsPerPage + 1;
              return fromNotes;
          },
          toNotes: function() {
              const toNotes = (this.cardsCurrentPage - 1) * this.cardsPerPage + this.cardsPerPage;
              if(toNotes > this.totalNotes) return this.totalNotes;
              return toNotes;
          }
      },
      components: {
        BlockPagination,
      },
    };
</script>

<template>
    <div class="additional-block-cards-notes">
        <div class="notes-table nav-title">
                <div class="rs-btn-group float-sm-right mb-1">
                </div>
                <div class="pagination-block-bottom rs-scroll rs-scroll--y">
                    <b-table
                            class="rs-scroll rs-scroll--y"
                            id="card-notes-table"
                            hover
                            :items="notes"
                            :fields="fields"
                            :current-page="cardsCurrentPage"
                            :per-page="cardsPerPage"
                    >
                        <template v-slot:cell(id)="data">
                            <div v-html="data.item.note"></div>
                        </template>
                        <template v-slot:cell(createdBy)="data">
                            <div>{{data.item.createdBy}}</div>
                        </template>
                        <template v-slot:cell(action)="data">
                            <div style="display: inline-block;"><a href="" @click.prevent="editNote(data.item)" data-toggle="modal" :data-target="isStatusActive? '#noteBoxModal': ''"><i class='bx bx-pencil'></i></a></div>
                            <div style="display: inline-block; margin-left:15px;"><a href="" @click.prevent="removeNote(data.item)" style="width: 19px; height: 19px;" class="nav-link btn_trush"></a></div>
                        </template>
                    </b-table>
                </div>
            </div>
            <block-pagination
                :role="'Notes'"
                :arrayOfSomeone="notes"
                :arrayOfSomeoneFiltered="notes"
                :currentPage="cardsCurrentPage"
                @changedCurrentPage="value => cardsCurrentPage = value"
                :perPage="cardsPerPage"
                @changedPerPage="value => cardsPerPage = value"
            >
            </block-pagination>
        <div class="modal fade" id="noteBoxModal" tabindex="-1" role="dialog" aria-labelledby="noteboxModalLabel" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-primary">
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                          <span aria-hidden="true">&times;</span>
                        </button>
                        <h4 style="display: inline-block" class="modal-title" id="noteboxModalLabel">{{note_id ? 'Edit Note' : 'New Note'}}</h4>
                    </div>
                    <div class="modal-body">
                        <div class="form-group">
                            <label for="message-text" class="form-control-label">Note:</label>
                            <textarea v-model="note" class="form-control" id="message-text" rows="10" style="height: auto;"></textarea>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button @click="saveNote" type="button" class="btn btn-primary mr-1" data-dismiss="modal"><i class="ti-save"></i> Save Note</button>
                        <button type="button" class="btn btn-secondary" data-dismiss="modal"><i class="ti-close"></i>Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .V3 .new-note-btn{
        position: fixed;
        top: 146px;
        right: 0;
        z-index: 1000;
        font-family: "Nunito Sans";
        font-size: 13px;
        font-weight: bold;
    }

    .nav-title {
      padding: 15px 15px 0 15px;
    }

    .notes-table .note-action a {
        color: #1B1E38;
    }

    .notes-table .note-action > a {
        font-size: 19px;
    }

    .notes-table .card-note {
        min-width: 200px;
        overflow-wrap: break-word;
        font-size: 12px;
        font-weight: 600;
    }

    .notes-table .created-by {
        max-width: 60px;
        min-width: 40px;
        font-size: 12px;
        font-weight: bold;
    }

    .notes-table .created-on {
        max-width: 80px;
        font-size: 12px;
        font-weight: 600;
    }

    .notes-table .action {
        min-width: 85px;
        max-width: 100px;
        width: 100px;
    }

    .notes-table .bx-pencil {
        font-size: 19px;
        color: black;
    }

    .notes-table tr td {
        padding: 16px 15px 14.5px 15px !important;
    }

    /* .V3 .notes-table #card-notes-table tbody:before,
    .V3 .notes-table #card-notes-table tbody:after {
        content: '';
        display: inline-block;
        width: 15px;
        height: 100%;
        background-color: #ffffff;
        position: absolute;
        left: 0;
        top: 120px;
    }

    .V3 .notes-table #card-notes-table tbody:after {
        left: unset;
        right: 0;
    } */
</style>
