import { render, staticRenderFns } from "./email-validator.vue?vue&type=template&id=543550e7&scoped=true"
import script from "./email-validator.vue?vue&type=script&lang=js"
export * from "./email-validator.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "543550e7",
  null
  
)

export default component.exports