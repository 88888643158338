<script>
import {VStepper} from 'vue-stepper-component';
import GoodWizard from '../../utility/vueGoodWizard';
import Axios from 'axios';
import ImageSelector from '../../utility/image-selector';
import AdvancedSearch from '../../utility/advanced-search';

export default {
  name: 'EstimateOptionsSendToEIO',
  data: function () {
    return {
      estimate: {
        ownerName: '',
        make: '',
        model: '',
        rego: '',
        insurerName: '',
        claimNumber: '',
        number: '',
        jobId: '',
        type:'estimate',
        estimateId:0,
      },
      isShowNextButton: true,
      isShowBackButton: false,
      isShowSendButton: false,
      images: [],
      allFilesSelected: false,
      allEstimateFilesSelected: false,
      selectedImages: [],
      steps: [
        {
          label: 'Confirm Estimate',
          slot: 'estimate-options-send-to-eio-step-1',
        },
        {
          label: 'Select Images',
          slot: 'estimate-options-send-to-eio-step-2',
        },
      ],
      step: 1,
    };
  },
  watch: {
  },
  methods: {
    clickBack() {
      this.$refs.estimateOptionsSendToEIO.goBack();
      if (this.$refs.estimateOptionsSendToEIO.currentStep === 0) {
        this.isShowBackButton = false;
      }
      if (this.$refs.estimateOptionsSendToEIO.currentStep !== 1) {
        this.isShowSendButton = false;
      }
      if (this.$refs.estimateOptionsSendToEIO.currentStep == 0) {
        this.isShowNextButton = true;
      }
    },
    clickNext() {
      this.$refs.estimateOptionsSendToEIO.goNext();
      if (this.$refs.estimateOptionsSendToEIO.currentStep === 1) {
        this.isShowBackButton = true;
      } else {
        this.isShowBackButton = false;
      }
      if (this.$refs.estimateOptionsSendToEIO.currentStep == 1) {
        this.isShowNextButton = false;
        this.isShowSendButton = true;
      }
    },
    toggleSelectAllFiles() {
      if (this.allFilesSelected) {
        this.files.forEach(file => file.selected = false);
      } else {
        this.files.forEach(file => file.selected = true);
      }
    },
    toggleSelectAllEstimateFiles() {
      if (this.allEstimateFilesSelected) {
        this.estimatePdfFiles.forEach(file => file.attached = false);
      } else {
        this.estimatePdfFiles.forEach((file) => {
          if ((this.estimate.isAdjusted && file.type == 'adjusted') || file.type == 'original') {
            file.attached = true;
          } else {
            file.attached = false;
          }
        });
      }
    },
    loadSendToEIO: function (estimate_id) {
      NProgress.start();
      Axios.get(`/fe/estimate/${estimate_id}/send-to-eio-wizard`)
          .then(response => {
            this.estimate = response.data.data;
            this.actionType = response.data.type;

            this.images = response.data.images;
            this.images = this.images.map(image => {
              this.$set(image, 'selected', false);
              return image;
            });
            // this.files = response.data.files;
            // this.files = this.files.map(file => {
            //   this.$set(file, 'selected', false);
            //   return file;
            // });
          })
          .catch(error => {
            toastr.error("Error occurred while loading estimate info");
            console.log(error);

          })
          .finally(e => {
            NProgress.done();
          });
    },
    clickCancel: function () {
      let estimateId = this.estimate?.estimateId>0?this.estimate.estimateId:this.$route.params.estimate_id;
      this.$router.push('/estimates/view/' + estimateId);
      return;
    },
    clickSend: function () {
      let estimate_id = this.$route.params.estimate_id;
      let parrentEstimateId = this.estimate?.estimateId>0?this.estimate.estimateId:estimate_id;
      let pdfs = [];

      //this.estimatePdfFiles.forEach(pdf => {
      //  if (pdf.attached) {
      //    pdfs.push(pdf.type);
      //  }
      //});

      let data = JSON.stringify({
        images: this.selectedImages,
        estimateId: estimate_id,
        //testMode: true,
      });

      NProgress.start();

      Axios.post(`/fe/estimate/${estimate_id}/send-to-eio`, data)
          .then(response => {
            if(!response.data || !response.data._status){
              return toastr.error('Cannot send XML!');
            }
            toastr.success('Quote successfully sent! Please contact with Estimage Online to confirm all data is correct');
            this.$router.push(`/estimates/view/${parrentEstimateId}`);
            window.open(
                `${appConfig.estimageOnlineAppUrl}/jobViewer/${this.estimate.jobId}`,
                "_blank",
            );
            // this.$bvModal.msgBoxOk(response.data.xml)
          }).finally(() => NProgress.done())
    },
    onImageSelect: function (imgs) {
      this.selectedImages = imgs;
    }
  },
  computed: {
    isAllFilesSelected() {
      if (this.selectedFiles.length > 0 && this.selectedFiles.length === this.files.length) {
        this.allFilesSelected = true;
        return true;
      } else {
        this.allFilesSelected = false;
        return false;
      }
    },
    selectedFiles() {
      try {
        let arr = _.filter(this.files, {'selected': true});
        let res = [];
        _.forEach(arr, function (i) {
          res.push(i.id);
        });
        return res;
      } catch (e) {
        return [];
      }
    }
  },
  mounted: function () {
    this.loadSendToEIO(this.$route.params.estimate_id);
  },
  components: {
    VStepper,
    GoodWizard,
    ImageSelector,
    AdvancedSearch,
  },
};

</script>

<template>
  <div class="estimate-options-send-to-eio">
    <div class="page-header">
      <h4>Send To Estimage Online - {{estimate.number}} <span v-if="estimate.ownerName">| {{estimate.ownerName}} </span><span v-if="estimate.rego">| {{estimate.rego}}</span></h4>
      <ol class="breadcrumbs">
        <li class="breadcrumb-item home">
          <router-link :to="'/'">Home</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/estimates'">View Estimates</router-link>
        </li>
        <li class="breadcrumb-item">
          <router-link :to="'/estimates/view/'+$route.params.estimate_id">Edit Estimate</router-link>
        </li>
        <li class="breadcrumb-item">Options</li>
        <li class="breadcrumb-item active">Send To Estimage Online</li>
      </ol>
    </div>
    <div class="">

      <good-wizard
          :steps="steps"
          :currentStep="step"
          ref="estimateOptionsSendToEIO">
        <div slot="estimate-options-send-to-eio-step-1" class="estimate-options-send-to-eio-step-1">
          <div class="header">
            Confirm Estimate
          </div>
          <div class="subheader">
            Review the estimate information you are about to send.
          </div>
          <div>
            <template>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Vehicle Owner Name</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.ownerName" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Rego</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.rego" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Make</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.make" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Model</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.model" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Insurer Name</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.insurerName" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Claim Number</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.claimNumber" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color':true}">Estimage Job ID</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" v-model="estimate.jobId" :disabled="true">
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <div class="card-block bg-white">
                  <div class="form-group row">
                    <label class="col-sm-5 col-5 col-form-label" :class="{'grey-color': true}">
                      <template v-if="!estimate || estimate?.type != 'supplement'">Estimate</template><template v-else>Supplement</template> Number</label>
                    <div class="col-sm-7 col-7">
                      <input type="text" class="form-control" :value="estimate.number" :disabled="!true">
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div slot="estimate-options-send-to-eio-step-2" class="estimate-options-send-to-eio-step-2">
          <div class="header">
            Select Images
          </div>
          <div class="subheader">
            Choose the images to attach by ticking the box next to the image
          </div>
          <div class="box box-block">
            <div class="row">
              <image-selector :isSendToEIOWizard="true" :images="images" :path="''" @onChange="onImageSelect"></image-selector>
            </div>
          </div>
        </div>
      </good-wizard>

      <div class="wizard__buttons">
        <div class=" pull-right">
          <button
              v-if="isShowNextButton"
              class="btn btn-primary pull-right button-next"
              type="button"
              @click="clickNext"
              :tabindex="4"

          >
            Next
          </button>
          <button
              v-if="isShowSendButton"
              class="btn btn-primary pull-right button-card-estimate"
              type="button"
              @click="clickSend"
              style=""
          >
            Send
          </button>
          <button
              v-if="isShowBackButton"
              class="btn btn-outline-primary-light pull-right button-back"
              type="button"
              @click="clickBack"
          >
            Back
          </button>
          <button
              class="btn btn-outline-primary pull-left button-cancel"
              type="button"
              @click="clickCancel"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
.V3 .wizard .wizard__body table.b-table tr th,
.V3 .wizard .wizard__body table.b-table tr td {
  padding: 13px 15px;
}

.grey-color {
  opacity: 0.6;
}

.V3 .estimate-options-send-to-eio .header-check {
  padding: 0.75rem;
  width: 5%;
  min-width: 45px;
}

/* The original select box is having transparent bg, this is an adjustment when placed on table header */
.V3 .estimate-options-send-to-partssearch .header-check .form-check-inline {
  background-color: #FFFFFF;
  border-radius: 3px;
}
</style>
<style>
.V3 .estimate-options-send-to-eio .wizard__body {
  position: relative;
  width: 100%;
  margin: 0px auto 0 auto;
  padding-top: 30px;
}

.V3 .estimate-options-send-to-eio .wizard__steps {
  width: 900px;
}

.V3 .estimate-options-send-to-eio .wizard__body .estimate-options-send-to-eio-step-1 {
  margin: 0 auto;
  min-width: 573px;
  width: 573px;
  margin: 0px auto 0 auto;
}

.V3 .estimate-options-send-to-eio .wizard__body .estimate-options-send-to-eio-step-1 .table th {
  color: #1C1F39;
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  /*   vertical-align: top; */
}

.V3 .estimate-options-send-to-eio .wizard__body .estimate-options-send-to-eio-step-1 .table td {
  color: #1C1F39;
  font-size: 12px;
  font-weight: bold;
  line-height: 16px;
}

.V3 .estimate-options-send-to-eio .wizard__body .estimate-options-send-to-eio-step-2 {
  min-width: 900px;
  width: 900px;
  margin: 0px auto 0 auto;
}

.V3 .estimate-options-send-to-eio .wizard__body .estimate-options-send-to-eio-step-3 {
  min-width: 900px;
  width: 900px;
  margin: 0px auto 0 auto;
}

.V3 .estimate-options-send-to-eio .wizard__buttons {
  max-width: 573px;
  margin: 0 auto 70px auto;
  /*padding-right: 15px;*/
}

</style>
<style scoped>
.V3 .estimate-options-send-to-eio .b-table > thead > tr {
  background-color: rgb(239, 239, 241) !important;
}

.V3 .select-suplier-show-more {
  display: inline-table;
  position: relative;
  transform: translateY(10px);
  margin-bottom: -25px;
  margin-left: 9px;
}

.V3 .select-suplier-show-more a {
  display: inline-block;
  color: #1C1F39;
  font-size: 12px;
  line-height: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.V3 .select-suplier-show-more a i {
  color: #1C1F39;
  font-size: 11px;
  margin-left: 3px;
}

.V3 .select-suplier-show-more p {
  color: #1C1F39;
  font-size: 12px;
  font-weight: 600;
}

.V3 .send-email-type {
  display: inline-table;
  position: relative;
  transform: translateY(10px);
  margin-bottom: -25px;
  margin-left: 9px;
}

.V3 .send-email-type p {
  margin-bottom: 0.7rem;
}

.V3 .send-email-type label.checkbox > span.text {
  display: inline-block;
  margin-top: 5px;
  padding-right: 15px;
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
}

.V3 .estimate-options-send-to-eio >>> .card-block {
  padding: 0px;
}

.V3 .estimate-options-send-to-eio .card-block {
  padding: 0px;
}

.V3 .wizard__buttons .pull-left {
  float: left !important;
  margin-right: 10px;
}

.V3 .wizard__buttons .pull-right {
  float: right !important;
  margin-left: 10px;
}

.V3 .wizard__buttons .btn {
  font-size: 13px;
  font-weight: bold;
  line-height: 18px;
  height: 38px;
}

.V3 .wizard__buttons .button-cancel {
  padding: 10px 21px;
  background: #FFFFFF;
  border: 1px solid rgba(28, 31, 57, 0.25);
  color: #1C1F39;
}

.V3 .wizard__buttons .button-card {
  padding: 10px 15px;
  background: #FFFFFF;
  color: #5E79FF;
}

.V3 .wizard__buttons .button-card-estimate {
  padding: 10px 15px;
}

.V3 .wizard__buttons .button-next {
  padding: 10px 15px;
  width: 83px;
}

.V3 .wizard__buttons .button-back {
  padding: 10px 26px;
  background: #FFFFFF;
  border-color: #5E79FF;
  width: 83px;
}

@media (max-width: 823px) AND (min-width: 604px) {
  .V3 .card-new .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }

  .V3 .compact-sidebar .card-new .wizard__buttons {
    width: 573px;
    margin: 0 auto;
    min-width: 573px;
    padding-left: 0px;
    padding-right: 0px;
  }
}

@media (max-width: 603px) {
  .V3 .card-new .wizard__buttons {
    padding-left: 15px;
    padding-right: 15px;
    width: auto;
    min-width: auto;
  }
}

@media (max-width: 512px) {
  .V3 .card-new .wizard__buttons button {
    margin-bottom: 10px;
    float: none !important;
    margin-left: 0px;
    margin-right: 0px;
    display: block;
  }
}

</style>

<style scoped>
.V3 .estimate-options-send-to-eio .form-control:disabled {
  opacity: 1;
}

.V3 .estimate-options-send-to-eio .estimate-options-send-to-eio-step-1 .supplier-due-by-time select.form-control {
  padding-right: 20px !important;
  color: #1C1F39;
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.V3 .estimate-options-send-to-eio .estimate-options-send-to-eio-step-1 .supplier-due-by {
  width: 100px;
  margin: 15px auto 0 auto;
  text-align: left;
  color: rgba(28, 31, 57, 0.5);
  font-size: 13px;
  letter-spacing: 0;
  line-height: 18px;
}

.V3 .estimate-options-send-to-eio .estimate-options-send-to-eio-step-1 .select-parts .select-parts-check-all span.icon,
.V3 .estimate-options-send-to-eio .estimate-options-send-to-eio-step-1 .select-parts span.icon {
  margin-right: 0px;
}
</style>

<style>
.V3 .estimate-options-send-to-eio .card-block {
  padding: 0px;
}
</style>
