<script>
    /* eslint-disable*/
    import numeral from 'numeral';

    export default {
        name: 'currency-input',
        data: function () {
            return {
                isFocus: false,
                countOfClick: 0,
                formattedValue: this.value,
            };
        },
        props: {
            isTotalManual: {
                default: false,
            },
            prevCell: {
                type: String,
                default: '',
            },
            nextCell: {
                type: String,
                default: '',
            },
            value: 0,
            percentage: {
                default: false
            },
            format: {
                type: String,
                default: '$0,0'
            },
            isDisabled: {
                type: Boolean,
                default: false
            },
        },
        mounted: function () {
            this.formatValue();
        },
        watch: {
            value: {
                immediate: true,
                handler() {
                  if (!this.isFocus) {
                    this.$nextTick(() => {
                      this.formatValue();
                    })
                  }
                }
            },
        },
        methods: {
            toUSCurrency(x) {
                let n = Math.round(x * 100) / 100;
                return n.toLocaleString(
                    'en-US',
                    {
                        style: 'currency',
                        currency: 'USD',
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                    }
                );
            },
            onChange: function () {
                //console.log('onChange');
                this.emitEvent('onChange');
            },
            selectAll() {
              this.$refs.input.focus();
              this.countOfClick = 1;
              setTimeout(() => {
                this.$refs.input.selectionStart = 0;
                this.$refs.input.selectionEnd = this.$refs.input.value.length;
              }, 50)
            },
            updateValue: function (value, event=null) {
                //console.log('updateValue',value, event);
                let selection = this.$refs.input.selectionStart;
                let lv = value.toString().substr(0, selection)
                lv = lv.replace(/[^0-9.]+/g, '');
                selection = lv.length;
                if (this.isTotalManual && (value == '' || value === null)) {
                    var result = null;
                }
                else if (value == '-') {
                    var result = '-';
                }
                else if (value == '-.') {
                    var result = '-0.';
                }
                else if (value == '-0') {
                    var result = '-0';
                }
                else if (value == '-0..') {
                    var result = '-0.';
                }
                else if (value == '-0.0') {
                    var result = '-0.0';
                }
                else if (value == '0-') {
                    var result = '0';
                }
                else if (value == '-0.') {
                    var result = '-0.';
                   // console.log('found',);
                }
                else {
                    var result;
                    if(value == '0.0'){
                      result = '0.0';
                    } else if(value == '.'){
                      result = '0.';
                      selection += 1;
                    } else {
                      result = numeral(value).format('0.[00]');
                    }
                }
                //console.log('updateValue-finish',result);

                if (result && result.includes('-') && value !== '-.') {
                  selection++;
                } else if (value == '-.') {
                  selection += 2
                }
                this.$emit('input', result);
                setTimeout(() => {
                    this.$refs.input.selectionStart = this.$refs.input.selectionEnd = selection;
                }, 50)
            },
            formatValue: function () {
                if (this.value === null || this.value === undefined || this.value === '')
                {
                   // console.log('break.no format.return', this.value);
                  this.formattedValue = ''
                    return;
                }
                if (this.percentage) {
                    //this.value = this.value /100;
                    if ((numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) > 100) {
                        this.formattedValue = 100 + '%';
                    } else {
                        this.formattedValue = (numeral(this.value).format(this.format)).slice(0, (numeral(this.value).format(this.format)).length - 3) + '%';
                    }
                } else if ((this.value !== '' && this.value !== undefined) && !this.isTotalManual) {
                    this.formattedValue = numeral(this.value).format(this.format);
                    this.$emit('blur');
                } else if ((this.value !== null && this.value !== undefined) && this.isTotalManual) {
                    this.formattedValue = numeral(this.value).format(this.format);
                    this.$emit('blur');
                } else if ((this.value === null || this.value === undefined) && this.isTotalManual) {
                    this.$emit('blur');
                }
            },

            setValue: function (val) {
                //console.log('setValue');
                let result = numeral(val).format('0.[00]');
                let formattedResult = numeral(result).format(this.format);
                this.$emit('input', result);
                let vm = this;
                setTimeout(function () {
                    vm.$refs.input.value = formattedResult;
                }, 0);
            },
            emitEvent: function (event) {
                //console.log('emitEvent', event);
                this.$emit(event);
            },
            focus: function (e) {
                // console.log('focus');
              //  this.$emit('updateDrag', false);
                if (e && e.target) {
                  if (e.target.selectionStart == 0 && e.target.selectionEnd == e.target.value.length) {
                   //   console.log('test.focus');
                    //  e.target.selectionEnd = e.target.selectionStart = e.target.value.length
                  }
                }
                this.isFocus = true;
                this.$refs.input.focus();
            },
            click: function (event) {
                console.log('click');
                this.isFocus = true;
                this.countOfClick = 1 + this.countOfClick;
               // console.log('countOfClick', this.countOfClick);
                if (this.countOfClick === 1 && !this.isDisabled) {
                  this.$refs.input.focus();
                  this.$refs.input.select();
                  // this.updateValue(this.value);
                   // console.log('countOfClick=== 1. FOCUS.SELECT');
                }else{
                    this.$refs.input.focus();
                  //  console.log('countOfClick=== Just Focus');
                }
            },
            blur: function () {
               // console.log('blur');
                this.$emit('blur');
              //  this.$emit('updateDrag', true);
               // this.$refs.input.blur(); //ToDO Why needs this event?
                this.countOfClick = 0;
                this.isFocus = false;
                this.formatValue();
            },
            keyRight(event) {
              this.$emit('onKeyRight', {event: event, cell: this.nextCell});
              this.countOfClick = 0;
            },
            keyLeft(event) {
              this.$emit('onKeyLeft', {event: event, cell: this.prevCell});
              this.countOfClick = 0;
            }
        }
    };

</script>

<template>
    <input ref="input"
           type="text"
           v-bind:value="formattedValue"
           v-on:keypress="emitEvent('onKeyPress')"
           v-on:keydown.left="keyLeft($event)"
           v-on:keydown.right="keyRight($event)"
           v-on:keydown.up="emitEvent('onKeyUp')"
           v-on:keydown.down="emitEvent('onKeyDown')"
           v-on:keydown.enter="emitEvent('onKeyEnter')"
           v-on:input="updateValue($event.target.value, $event)"
           v-on:focus="focus($event)"
           v-on:focusout="emitEvent('focusout')"
           v-on:blur="blur"
           v-on:click="click($event)"
           style="opacity: 1"
           :readonly="isDisabled"
           @change="onChange()">
</template>





