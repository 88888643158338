<template>
  <div class="whitespace-nowrap">
    <div v-if="!item.activity || !item.activity.Email || !item.activity.Email.date" class="notf-icon">
      <img :src="iconEmailGrey"/>
    </div>
    <div v-else @click="goToEmail(item)" class="notf-icon">
      <img :id="`i-email-${name}`" :src="iconEmailBlue"/>
      <b-tooltip
          :target="`i-email-${name}`"
          triggers="hover"
          custom-class="ps-tooltip"
      >
        Unread {{ item.activity.Email.smsId ? 'SMS' : 'email' }} message at:<br/>
        <strong>{{ item.activity.Email.date }}</strong>
      </b-tooltip>
    </div>

    <div v-if="item.activity &&  item.activity.External && item.activity.External.from && item.activity.External.from  != ''"
         class="notf-icon">
      <img :id="`i-external-${name}`" :src="iconExternalBlue"/>
      <b-tooltip
          :target="`i-external-${name}`"
          triggers="hover"
          custom-class="ps-tooltip"
      >
        New data received from <strong>{{ item.activity.External.from }}</strong><br/>
        at <strong>{{ item.activity.External.date }}</strong>
      </b-tooltip>
    </div>
    <div v-else class="notf-icon">
      <img :src="iconExternalGrey"/>
    </div>

    <div
        v-if="item.activity && item.activity.Assignment && item.activity.Assignment.type"
        class="notf-icon"
    >
      <img :id="`i-task-${name}`" :src="iconAssignBlue"/>
      <b-tooltip
          v-if="item.activity.Assignment.type && item.activity.Assignment.type == 'Task'"
          :target="`i-task-${name}`"
          triggers="hover"
          custom-class="ps-tooltip"
      >
        Task Assigned From:<br/>
        <strong>{{ item.activity.Assignment.from }}</strong>
      </b-tooltip>
      <b-tooltip
          v-if="item.activity && item.activity.Assignment.type && item.activity.Assignment.type == 'Card'"
          :target="`i-task-${name}`"
          triggers="hover"
          custom-class="ps-tooltip"
      >
        Card Assigned From:<br/>
        <strong>{{ item.activity.Assignment.from }}</strong>
      </b-tooltip>
    </div>
    <div v-else class="notf-icon">
      <img :src="iconAssignGrey"/>
    </div>

    <div
        v-if="item.activity && item.activity.Message && item.activity.Message.date"
        class="notf-icon"
    >
      <img :id="`i-message-${name}`" :src="iconMessageBlue"/>
      <b-tooltip
          :target="`i-message-${name}`"
          triggers="hover"
          custom-class="ps-tooltip"
      >
        Message From:<br/>
        <strong>{{ item.activity.Message.from }}</strong>
      </b-tooltip>
    </div>
    <div v-else class="notf-icon">
      <img :src="iconMessageGrey"/>
    </div>
  </div>
</template>

<script>
import iconEmailGrey from '../assets/img/bx-envelope-gray.svg'
import iconEmailBlue from '../assets/img/bx-envelope-blue.svg'
import iconExternalGrey from '../assets/img/bx-link-external-gray.svg'
import iconExternalBlue from '../assets/img/bx-link-external-blue.svg'
import iconAssignGrey from '../assets/img/bx-user-gray.svg'
import iconAssignBlue from '../assets/img/bx-user-blue.svg'
import iconAssign from '../assets/img/bx-user.svg'
import iconMessageGrey from '../assets/img/bx-message-rounded-gray.svg'
import iconMessageBlue from '../assets/img/bx-message-rounded-blue.svg'
import iconMessage from '../assets/img/bx-message-rounded.svg'

export default {
  props: {
    item: {
      type: Object,
      default: {
        card_id: '',
        activity: {
          Email: {
            date: '',
            id: 0,
            from: '',
            activity_id: 0,
          },
          Assignment: {
            from: '',
            type: ''
          },
          Message: {
            from: '',
            date: ''
          },
          External: {
            from: '',
            date: '',
          },
        },
      }
    },
    // Unique String ID
    // must be unique row and tab
    name: {
      type: String,
    }
  },
  data () {
    return {
      iconEmailGrey,
      iconEmailBlue,
      iconExternalGrey,
      iconExternalBlue,
      iconAssignGrey,
      iconAssignBlue,
      iconMessageGrey,
      iconMessage,
      iconMessageBlue
    }
  },
  methods: {
    goToEmail (item) {
      this.$router.push(
          { name: 'CardsEdit', params: { action: 'goToEmail', card_id: item.card_id, activityId: item.activity.activityId } }
      )
    }
  }
}
</script>