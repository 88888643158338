<script>
    import {appConfig} from "../../config";
    import Vue from 'vue';
    import {isMobile, isMobileOnly} from '../../deviceDetect/index';
    import {mapGetters} from "vuex";
    import { vAvatar as Avatar} from '@amaury-tobias/v-avatar'
    import fullscreen from 'vue-fullscreen';

    Vue.use(fullscreen);

    export default {
        name: 'site-header',
        components: { Avatar },
        data() {
            return {
                fullscreen: false,
                isImageError: false,
                isMobile,
            }
        },
        methods: {
            toggleSideBarFirst: function () {
                console.log('toggle1')
                let body = document.querySelector('body');
                let first =  document.querySelector('.site-header .sidebar-toggle-first');
                console.log('body.classList',body.classList);
                body.classList.toggle('site-sidebar-opened');
                console.log('body.classList',body.classList);
                console.log('first', first)
                return false;
            },
            toggleSideBarSecond: function () {
                console.log('toggle2')
                let body = document.querySelector('body');
                //let second =  document.querySelector('.site-header .sidebar-toggle-second');
                body.classList.toggle('compact-sidebar');

                console.log('body.classList',body.classList);
              //  console.log('second', second)
                return false;
            },
            removeInStorageSavedTabs() {
              if (!_.isEmpty(this.$route.params.estimate_id)) {
                this.$storage.remove("views.estimateEdit." + this.$route.params.estimate_id + ".defaultView");
              } else if (!_.isEmpty(this.$route.params.card_id)) {
                this.$storage.remove("views.cardsEdit." + this.$route.params.card_id + ".defaultView");
              } else if (!_.isEmpty(this.$route.params.invoice_id)) {
                if (this.$route.path.includes('/excess')) {
                  this.$storage.remove("views.invoiceExcess." + this.$route.params.invoice_id + ".defaultView");
                } else {
                  this.$storage.remove("views.invoiceView." + this.$route.params.invoice_id + ".defaultView");
                }
              }
            },
            logout: function () {
                this.$store.dispatch('logout')
                    .then(() => {
                        this.removeInStorageSavedTabs();
                        document.body.style.zoom = null;
                        this.$router.push('/')
                    })
            },
            onImageError() {
                this.isImageError = true
            },
            toggleFullscreen() {
                let body = document.querySelector('body');
                //console.log('Click1');
                this.$fullscreen.toggle(body, {
                    wrap: false,
                    callback: this.fullscreenChange
                });
            },
            fullscreenChange(fullscreen) {
                this.fullscreen = fullscreen;
            },
            setCompactSidebar: function () {
              this.$nextTick(() => {
                document.body.classList.add('compact-sidebar');
              });
            },
        },
      computed: {
            ...mapGetters({
                isTechnicalUser: 'isTechnicalUser',
                isCustomerUser: 'isCustomerUser',
                isEstimatorUser: 'isEstimatorUser',
                isPDRTechnicianUser: 'isPDRTechnicianUser',
                isShopManagerUser: 'isShopManagerUser'
            }),
            count: function () {
                return this.$store.state.count;
            },
            userInfo: function () {
                return this.$store.state.userInfo;
            },
            profile_picture_url: function () {
                return this.$store.getters.avatar;
                //return appConfig.baseAPIURL + '/img/avatars/' + this.userInfo.profile_picture;
            },
            avatarStyle() {
                return {
                    'font': `16px / 40px 'Nunito Sans', sans-serif`,
                }
            }
        },
      mounted() {
        window.addEventListener("orientationchange", (event) => {
          console.log(123)
          if (isMobile) {
            this.setCompactSidebar()
          }
        });
      },
    };
</script>

<template>
    <div>

        <!-- Preloader -->
        <div class="preloader" style="display: none"></div>
        <!-- Header -->
        <div class="site-header">
            <nav class="navbar navbar-light">
                <div class="navbar-left center-middle">
                    <a @click="'/'" class="navbar-brand-logo-svg-header"></a>
                    <div @click="toggleSideBarFirst" class="toggle-button light sidebar-toggle-first float-xs-left hidden-md-up">
                        <span class="hamburger"></span>
                    </div>
                </div>
                <div class="navbar-right navbar-toggleable-sm collapse" id="collapse-1">
                    <div @click="toggleSideBarSecond" class="toggle-button light sidebar-toggle-second float-xs-left hidden-sm-down">
                        <span class="hamburger"></span>
                    </div>
                    <ul class="nav navbar-nav float-md-right d-flex items-center">
                        <li class="nav-item hidden-sm-down">
                            <span class="nav-link active full-name">{{userInfo.first_name}} {{userInfo.last_name}}</span>
                        </li>
                        <li class="nav-item dropdown hidden-sm-down">
                            <a href="#" data-toggle="dropdown" aria-expanded="false" class="d-flex items-center justify-content-center">
                                <span class="avatar">
                                    <img class="" :src="profile_picture_url" @error="onImageError" class="hidden-xs-up hidden-sm-down">
                                    <avatar
                                        :username="`${userInfo.first_name} ${userInfo.last_name}`"
                                        :src="!isImageError ? profile_picture_url : ''"
                                        :size="40"
                                        rounded
                                        :customStyle="avatarStyle"
                                    />
                                </span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right animated fadeInUp">
                                <span style="cursor: pointer;" class="dropdown-item" @click="$router.push({name: 'settings', params: {customTab: 'my-account', customSubTab: '',}}).catch(()=>{})">
                                  <i class="ti-user mr-0-5"></i>My Account
                                </span>
                                <span v-if="isShopManagerUser" @click="$router.push({name: 'business', params: {customTab: 'business-profile', customSubTab: 'details'}}).catch(()=>{})" style="cursor: pointer;" class="dropdown-item">
                                  <i class="ti-briefcase mr-0-5"></i>Business
                                </span>

                                <div class="dropdown-divider"></div>
                                <a href="#"  class="dropdown-item" @click="logout"><i class="ti-power-off mr-0-5"></i> Sign out</a>
                            </div>
                        </li>
                    </ul>
                    <ul class="nav navbar-nav">
                        <li class="nav-item hidden-sm-down">
                            <a  @click.prevent="toggleFullscreen" class="nav-link toggle-fullscreen clickable">
                                <i class="ti-fullscreen"></i>
                            </a>
                        </li>
                    </ul>
                </div>
            </nav>
        </div>
    </div>
</template>

<style>
    .navbar-light .navbar-brand {
        color: #212330 !important;
    }

    .center-middle {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    @media screen and (max-width: 767px) {
        .navbar-left.center-middle .toggle-button.light.sidebar-toggle-first.float-xs-left.hidden-md-up {
            position: absolute;
            left: 0;
        }
    }
</style>

<style>
    /* =================================================================
  Header
================================================================= */

    .V3 .fixed-header .site-header {
        position: fixed;
        top: 0;
        left: 220px;
        right: 0;
        z-index: 10000;
    }

    @media (max-width: 767px) {
        .V3 .fixed-header .site-header {
            left: 0;
        }

        .V3 .fixed-header .site-header .navbar-brand-logo-svg-header {
            background-image: url(../../assets/logos/logo2x.png);
            background-size: 100% auto;
            display: block;
            width: 120px;
            height: 24.31px;
            background-repeat: no-repeat;
        }

        .V3 .V3.fixed-header.site-sidebar-opened .site-header .navbar-brand-logo-svg-header
        {
         display: none;
        }
    }

    .V3 .fixed-header.compact-sidebar .site-header {
        left: 70px;
    }

    .V3 .site-header .navbar {
        padding: 0;
        background-color: #FFFFFF;
    }

    .V3 .site-header .navbar-left {
        background-color: #212330;
        text-align: center;
        border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    }

    @media (min-width: 768px) {
        .V3 .site-header .navbar-left {
            float: left;
            width: 220px;
            display: none;
        }

        .V3 .compact-sidebar .site-header .navbar-left {
            width: 70px;
            display: none;
        }
    }

    @media (max-width: 767px) {
        .V3 .site-header .navbar-left {
            background-color: #fff !important;
            height: 60px;
        }
    }

    .V3 .site-header .navbar-brand {
        display: inline-block;
        float: none;
        margin: 0;
        padding: 1.3rem 0 0;
        height: 60px;
    }

    .V3 .site-header .navbar-brand .logo {
        width: 100px;
        height: 25px;
    }

    @media (min-width: 768px) {
        .V3 .compact-sidebar .site-header .navbar-brand .logo {
            width: 25px;
        }
    }

    .V3 .site-header .navbar-right {
        padding: 0 15px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.25);
    }

    @media (min-width: 768px) {
        .V3 .site-header .navbar-right {
            margin-left: 0px;
            height: 60px !important;
        }

        .V3 .compact-sidebar .site-header .navbar-right {
            margin-left: 0px;
        }
    }

    .V3 .site-header .toggle-button {
        position: relative;
        width: 55px;
        height: 60px;
        line-height: 60px;
        font-size: 1.5rem;
        cursor: pointer;
    }

    .V3 .site-header .navbar-right .toggle-button {
        margin-left: -15px;
    }

    .V3 .site-header .toggle-button.light {
        color: rgba(0, 0, 0, 0.5);
    }

    .V3 .site-header .toggle-button.dark {
        color: rgba(255, 255, 255, 0.5);
    }

    .V3 .site-header .toggle-button span {
        position: absolute;
        display: block;
        top: 50%;
        right: 0;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header .toggle-button span.hamburger {
        left: 15px;
        margin-top: -1px;
        width: 23px;
        height: 3px;
        border-radius: 2px;
    }

    .V3 .site-header .toggle-button span.more {
        left: 50%;
        margin: -2px 0 0 -2px;
        width: 4px;
        height: 4px;
        border-radius: 50%;
    }

    .V3 .site-header .toggle-button.light span {
        background-color: #1B1E38;
    }

    .V3 .site-header .toggle-button.dark span {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .V3 .site-header .toggle-button span:before,
    .V3 .site-header .toggle-button span:after {
        content: '';
        position: absolute;
        display: block;
        left: 0;
        width: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header .toggle-button span.hamburger:before,
    .V3 .site-header .toggle-button span.hamburger:after {
        height: 3px;
        border-radius: 2px;
    }

    .V3 .site-header .toggle-button span.more:before,
    .V3 .site-header .toggle-button span.more:after {
        height: 4px;
        border-radius: 50%;
    }

    .V3 .site-header .toggle-button.light span:before,
    .V3 .site-header .toggle-button.light span:after {
        background-color: #1B1E38;
    }

    .V3 .site-header .toggle-button.dark span:before,
    .V3 .site-header .toggle-button.dark span:after {
        background-color: rgba(255, 255, 255, 0.5);
    }

    .V3 .site-header .toggle-button span.hamburger:before {
        top: -6px;
        width: 50%;
    }

    .V3 .site-header .toggle-button span.hamburger:after {
        bottom: -6px;
        width: 50%;
        margin-left: 50%;
    }

    .V3 .site-header .toggle-button span.more:before {
        top: -8px;
    }

    .V3 .site-header .toggle-button span.more:after {
        bottom: -8px;
    }

    .V3 .site-header .toggle-button.light:hover span,
    .V3 .site-header .toggle-button.light:hover span:before,
    .V3 .site-header .toggle-button.light:hover span:after,
    .V3 .site-header .toggle-button.light:focus span,
    .V3 .site-header .toggle-button.light:focus span:before,
    .V3 .site-header .toggle-button.light:focus span:after {
        background-color: #333333;
    }

    .V3 .site-header .toggle-button.dark:hover span,
    .V3 .site-header .toggle-button.dark:hover span:before,
    .V3 .site-header .toggle-button.dark:hover span:after,
    .V3 .site-header .toggle-button.dark:focus span,
    .V3 .site-header .toggle-button.dark:focus span:before,
    .V3 .site-header .toggle-button.dark:focus span:after {
        background-color: #FFFFFF;
    }

    .V3 .site-header .toggle-button-second {
        width: 45px;
        height: 59px;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
    }

    .V3 .site-header .navbar-right .toggle-button-second {
        margin: 0 -15px 0 15px;
    }

    .V3 .site-header .toggle-button-second.light {
        border-left: 1px solid rgba(0, 0, 0, 0.05);
        color: rgba(0, 0, 0, 0.5);
    }

    .V3 .site-header .toggle-button-second.dark {
        border-left: 1px solid rgba(255, 255, 255, 0.125);
        color: rgba(255, 255, 255, 0.7);
    }

    .V3 .site-header .toggle-button-second i {
        display: inline-block;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header .toggle-button-second.active i {
        -webkit-transform: rotate(180deg);
        transform: rotate(180deg);
    }

    .V3 .site-header .navbar-nav > li > a,
    .V3 .site-header .navbar-nav > li > span {
        padding-top: 1rem;
        padding-bottom: 1rem;
    }

    @media (min-width: 768px) {
        .V3 .site-header .navbar-nav > li > a,
        .V3 .site-header .navbar-nav > li > span {
            padding-top: 0;
            padding-bottom: 0;
            line-height: 60px;
        }
    }

    .V3 .site-header .navbar-nav > li > a i {
        position: relative;
        top: 2px;
        font-size: 1.3rem;
    }

    .V3 .site-header .navbar-nav > li .btn {
        vertical-align: baseline;
    }

    .V3 .site-header .navbar-nav > li .avatar img {
        vertical-align: top;
    }

    .V3 .site-header .navbar .dropdown-menu {
        margin-top: -1px;
        border-radius: 3px;
    }

    @media (max-width: 767px) {
        .V3 .site-header .navbar .navbar-toggleable-sm {
            position: absolute;
            top: 60px;
            left: 0;
            right: 0;
            padding: 1rem 2rem;
            background-color: #FFFFFF;
            border-bottom: 1px solid #DDDDDD;
        }
    }

    @media (max-width: 991px) {
        .V3 .site-header .navbar {
            margin-left: 0 !important;
        }
    }

    .V3 .site-header .header-form {
        padding-top: 1.1rem;
    }

    @media (max-width: 767px) {
        .V3 .site-header .header-form {
            padding: 0.75rem 0;
        }

        /* .dropdown-menu-mobile {
            position: absolute !important;
            left: -80px !important;
            top: 55% !important;
        } */
    }

    .V3 .site-header .header-form form {
        position: relative;
    }

    .V3 .site-header .header-form .btn {
        position: absolute;
        top: 1px;
        right: 1px;
        color: #999999;
    }

    @media (min-width: 768px) {
        .V3 .site-header .header-form .form-control {
            width: 180px;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }

        .V3 .site-header .header-form .form-control:focus {
            width: 300px;
        }

        /* .V3 .site-header .right-user-block-mobile {
            justify-content: flex-end;
            margin-right: 15px;
        } */
    }

    .V3 .site-header .dropdown-more {
        display: block;
        padding: 0.75rem 1rem;
        background-color: #EEEEEE;
        color: #999999;
        text-align: center;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }

    .V3 .site-header .dropdown-more:hover,
    .V3 .site-header .dropdown-more:focus {
        color: #333333;
    }

    .V3 .site-header .full-name {
        color: #1B1E38;
        font-size: 14px;
        font-weight: 600;
        letter-spacing: 0;
        line-height: 19px;
    }
</style>
